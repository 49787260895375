import { createContext, ReactNode, useCallback, useState } from 'react';

interface DialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  action: string;
  message: string;
}

interface ConfirmationDialogContextType {
  dialogProps: DialogProps;
  openDialog: (props: Omit<DialogProps, 'open'>) => void;
  closeDialog: () => void;
}

const ConfirmationDialogContext = createContext<
  ConfirmationDialogContextType | undefined
>(undefined);

export const ConfirmationDialogProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    onConfirm: () => {},
    action: '',
    message: ''
  });

  const openDialog = useCallback((props: Omit<DialogProps, 'open'>) => {
    setDialogProps({
      ...props,
      open: true
    });
  }, []);

  const closeDialog = useCallback(() => {
    setDialogProps((prev) => ({
      ...prev,
      open: false
    }));
  }, []);

  return (
    <ConfirmationDialogContext.Provider
      value={{ dialogProps, openDialog, closeDialog }}
    >
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export default ConfirmationDialogContext;
