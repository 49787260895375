import { Api } from 'api/Api';
import Cookies from 'js-cookie';

import { ENTITY_HEADER_NAME } from '@/components/EntitySelect.tsx';

import { CRM_URL } from './env';

export const crmApi = new Api({
  baseURL: CRM_URL,
  withCredentials: true,
  headers: {
    'x-version': '1.0.1',
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

crmApi.instance.interceptors.request.use((config) => {
  const entityName = Cookies.get(ENTITY_HEADER_NAME) ?? '';
  if (entityName) {
    config.headers.set(ENTITY_HEADER_NAME, entityName);
  }
  return config;
});

export { CRM_URL };
