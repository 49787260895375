import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';

import useConfirmationDialog from './useConfirmationDialog';

export const DEFAULT_MESSAGE = 'Are you sure you want to perform this action?';
export const DEFAULT_ACTION = 'perform this action';

export const ConfirmationDialog = () => {
  const { dialogProps, closeDialog } = useConfirmationDialog();

  const handleCloseDialog = async () => {
    if (dialogProps?.onCancel) dialogProps?.onCancel();
    closeDialog();
  };

  const handleConfirm = async () => {
    try {
      closeDialog();
      await dialogProps.onConfirm();
    } catch (error) {
      console.error('Error during confirmation action', error);
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <Dialog open={dialogProps.open} onOpenChange={handleCloseDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{dialogProps.message || DEFAULT_MESSAGE}</DialogTitle>
        </DialogHeader>
        <div className="py-4 text-gray-500 rounded-md">
          {dialogProps.action || `Are you sure you want to ${DEFAULT_ACTION}?`}
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="button"
              variant="secondary"
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
          </DialogClose>

          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
