import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConfigName } from '@/Features/Settings/config/types.ts';
import { queryClient } from '@/lib/api.ts';
import { crmApi } from '@/lib/crmApi.ts';
import { PageArgs } from '@/lib/types.ts';
import { AppConfigurations } from '@/types';

export const enum ConfigurationKEYS {
  adminConfigs = '/config',
  adminConfigByName = '/config/:name',
  adminConfigHistoryByName = '/config/:name/history'
}

export type AdminConfigResponse = {
  configName: string;
  configData: string;
  encrypted: boolean;
  createdAt: string;
};

export type AdminUpsertConfigArgs = {
  configName: string;
  configData: any;
  encrypted: boolean;
};

export type AdminListConfigsArgs = PageArgs<{
  search?: string;
}>;

export type AdminListConfigsResponse = AppConfigurations[];

export const useAdminListConfigs = (args?: AdminListConfigsArgs) =>
  useQuery({
    queryKey: [ConfigurationKEYS.adminConfigs, args],
    queryFn: () => crmApi.config.getLatest(args).then((res) => res.data)
  });

export const useAdminUpsertConfig = () =>
  useMutation({
    mutationFn: (arg: AdminUpsertConfigArgs) =>
      crmApi.config.upsertConfig(arg).then(async (res) => {
        queryClient.invalidateQueries({
          queryKey: [ConfigurationKEYS.adminConfigs]
        });
        queryClient.invalidateQueries({
          queryKey: [ConfigurationKEYS.adminConfigByName, arg.configName]
        });
        return res.data;
      })
  });

export const useAdminConfigByName = (name: string) => {
  return useQuery<AppConfigurations['configData'], AxiosError>({
    queryKey: [ConfigurationKEYS.adminConfigByName, name],
    queryFn: async ({ queryKey: [_k, name] }) => {
      return crmApi.config.getByName(name as string).then((res) => res.data);
    }
  });
};

export type ConfigurationName = Parameters<
  typeof crmApi.config.verifyIntegration
>[0]['name'];

export const useVerifyConfigIntegrity = () => {
  return useMutation({
    mutationFn: async ({ name, data }: { name: ConfigName; data: any }) => {
      return crmApi.config.verifyIntegration({
        name: name as any,
        data
      });
    }
  });
};

export const useAdminConfigHistoryByName = (name: string) =>
  useQuery({
    queryKey: [ConfigurationKEYS.adminConfigHistoryByName, name],
    enabled: false,
    queryFn: ({ queryKey: [_k, name] }) =>
      crmApi.config.getHistoryByName(name as string).then((res) => res.data)
  });

export const useAdminDeleteConfigsByNames = () =>
  useMutation({
    mutationFn: async (names: string[]) => {
      const res = await crmApi.config.deleteManyByName({ names });
      await queryClient.invalidateQueries({
        queryKey: [ConfigurationKEYS.adminConfigs]
      });
      return res.data;
    }
  });

// drivewealth config hooks

export const useAdminListKYCQuestionsNames = (enabled?: boolean) =>
  useQuery<string[], AxiosError>({
    queryKey: ['/kyc/questions/names'],
    staleTime: Infinity,
    enabled,
    queryFn: () => crmApi.kyc.getAllQuestionsNames().then((res) => res.data)
  });

export const useAdminListUserMetadataFields = (enabled?: boolean) =>
  useQuery<string[], AxiosError>({
    queryKey: ['/users/metadata/fields'],
    staleTime: Infinity,
    enabled,
    queryFn: () => crmApi.fields.getAllMetadataFields().then((res) => res.data)
  });

export const useAdminListUserFields = (enabled?: boolean) =>
  useQuery<string[], AxiosError>({
    queryKey: ['/users/fields'],
    staleTime: Infinity,
    enabled,
    queryFn: () => crmApi.fields.getAllUserFields().then((res) => res.data)
  });

export const useAdminTransactionFields = (enabled?: boolean) =>
  useQuery<string[], AxiosError>({
    queryKey: ['/transactions/fields'],
    staleTime: Infinity,
    enabled,
    queryFn: () =>
      crmApi.fields.getAllTransactionFields().then((res) => res.data)
  });

export const useAdminTransactionEntryFields = (enabled?: boolean) =>
  useQuery<string[], AxiosError>({
    queryKey: ['/transactions/entry/fields'],
    staleTime: Infinity,
    enabled,
    queryFn: () =>
      crmApi.fields.getAllTransactionEntryFields().then((res) => res.data)
  });

export const useAdminTradingAccountFields = (enabled?: boolean) =>
  useQuery<string[], AxiosError>({
    queryKey: ['/trading/accounts/fields'],
    staleTime: Infinity,
    enabled,
    queryFn: () =>
      crmApi.fields.getAllTradingAccountFields().then((res) => res.data)
  });
