import { PuffLoader } from 'react-spinners';

import { useTheme } from '@/hooks/useTheme';

interface LoaderProps {
  size?: number;
  loading?: boolean;
  className?: string;
}

const Loader = ({ size = 60, loading = true, className = '' }: LoaderProps) => {
  const { theme } = useTheme();

  return (
    <div
      className={`h-full w-full flex justify-center items-center ${className}`}
    >
      <PuffLoader
        loading={loading}
        color={theme === 'dark' ? 'white' : 'black'}
        size={size}
      />
    </div>
  );
};

export default Loader;
