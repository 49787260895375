import { jwtDecode } from 'jwt-decode';
import { autorun, makeAutoObservable } from 'mobx';

import { crmApi } from './crmApi';

class Session {
  access_token = '';
  refresh_token = '';
  decoded: Record<string, string | number> | null = null;
  user = '';

  constructor(keys: {
    access_token: string;
    refresh_token: string;
    user: string;
  }) {
    keys.access_token && this.login(keys);

    makeAutoObservable(this);
  }

  login(keys: { access_token: string; refresh_token: string }) {
    try {
      const [, body] = `${keys?.access_token}`.split('.');
      if (!body) throw new Error('Invalid access token');
      this.decoded = JSON.parse(keys.access_token ? atob(body) : 'null');
      this.user = JSON.stringify(jwtDecode(keys.access_token));
      this.access_token = keys.access_token;
      this.refresh_token = keys.refresh_token;
      crmApi.instance.defaults.headers['Authorization'] =
        crmApi.instance.defaults.headers.common['Authorization'] =
          `Bearer ${this.access_token}`;
    } catch (error) {
      this.clear();
      console.warn('login failed', error);
    }
  }

  clear() {
    this.decoded = null;
    this.user = '';
    this.access_token = '';
    this.refresh_token = '';
    delete crmApi.instance.defaults.headers['Authorization'];
  }
}

export const sessionStore = new Session({
  access_token: sessionStorage.access_token,
  refresh_token: sessionStorage.refresh_token,
  user: sessionStorage.user
});

autorun(() => {
  // save tokens to session storage
  sessionStorage.access_token = sessionStore.access_token;
  sessionStorage.refresh_token = sessionStore.refresh_token;
  sessionStorage.user = sessionStore.user;
});

// crmApi.instance.interceptors.response.use(undefined, (error) => {
//   if (error.response?.status === 401) {
//     sessionStore.clear();
//   }
//   return Promise.reject(error);
// });
