export enum ConfigName {
  FIREBASE = 'firebase',
  SENDGRID = 'sendgrid',
  THEME = 'theme',
  KEY_VALUE = 'keyvalue',
  LEAD_FORM = 'leadform',
  PLUGINS = 'plugins',
  DRIVE_WEALTH = 'drivewealth',
  MT_ACCOUNT = 'mt_account',
  TWILIO = 'twilio',
  TRANSACTIONS_DEFAULT_COMMENTS = 'transactions_default_comments',
  EXCHANGE_RATES_CRON_JOBS = 'exchange_rates_cron_jobs',
  TRANSACTION_APPROVAL = 'transaction_approval',
  EMAIL_SERVICE = 'email_service',
  STORAGE_SERVICES = 'storage_services',
  BALANCE_ARCHIVE = 'balance_archive'
}
