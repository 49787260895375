import { useContext } from 'react';

import DialogContext from './ConfirmationDialogProvider';

const useConfirmationDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error(
      'useConfirmationDialog must be used within a DialogProvider'
    );
  }
  return context;
};

export default useConfirmationDialog;
