import { QueryCache, QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';

import { sessionStore } from './Session';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error, query) {
      console.log('QUERY ERROR', error.name, error.message, query);
    }
  }),
  defaultOptions: {
    queries: {
      retry: 3
    }
  }
});

const healthCheckType = z.object({
  uptime: z.number(),
  db: z.string(),
  timestamp: z.number(),
  used_memory: z.number(),
  free_memory: z.number()
});
const postConfig_Body = z.object({
  configName: z.string(),
  configData: z.unknown(),
  encrypted: z.boolean()
});
const configType = z.object({
  configName: z.string(),
  configData: z.unknown(),
  encrypted: z.boolean(),
  createdAt: z.string()
});
const configArrayType = z.array(
  z
    .object({
      configName: z.string(),
      configData: z.unknown(),
      encrypted: z.boolean(),
      createdAt: z.string()
    })
    .passthrough()
);

export const schemas = {
  healthCheckType,
  postConfig_Body,
  configType,
  configArrayType
};

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_SOME_API
});

apiClient.interceptors.request.use((config) => {
  const token = sessionStore.access_token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// apiClient.post('/config', {
//   configName: 'theme',
//   configData: {
//     logo: 'http://localhost:5173/apple-touch-icon.png',
//   },
//   encrypted: false,
// })
