import { createContext, useContext } from 'react';

import { useAdminConfigByName } from '@/Features/Settings/config/hooks.ts';
import { ConfigName } from '@/Features/Settings/config/types.ts';

export type ThemeContext = {
  theme: 'dark' | 'light';
  toggleTheme: () => void;
};

export const themeContext = createContext<ThemeContext>({
  theme: 'dark',
  toggleTheme: () => {}
});

export const useTheme = () => useContext(themeContext);

export const useThemeConfig = () => useAdminConfigByName(ConfigName.THEME);
