import { type ClassValue, clsx } from 'clsx';
import { memoize } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { z, ZodNumber, ZodObject, ZodString } from 'zod';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function replaceSearchParam(
  qs: Record<string, string | number | boolean | null>,
  replace = false
) {
  const params = new URLSearchParams(location.search);
  for (const [key, value] of Object.entries(qs)) {
    if (value === null) {
      params.delete(key);
    } else {
      params.set(key, `${value}`);
    }
  }

  const str = `${params}`;

  if (replace) {
    history.replaceState(
      null,
      '',
      str ? `${location.pathname}?${str}` : location.pathname
    );
  } else {
    history.pushState(
      null,
      '',
      str ? `${location.pathname}?${str}` : location.pathname
    );
  }
}

export const sortBy = memoize(function sortBy<T>(arr: T[], key: keyof T) {
  return [...arr].sort((a, b) =>
    a[key] === b[key] ? 0 : a[key] > b[key] ? 1 : -1
  );
});

type FieldType =
  | 'text'
  | 'number'
  | 'boolean'
  | 'date'
  | 'url'
  | 'email'
  | 'select';

interface FieldConfig {
  name: string;
  type: FieldType;
  required?: boolean;
  defaultValue?: any;
  min?: number;
  max?: number;
  pattern?: string;
  options?: string[];
  description?: string;
  section?: string;
}

export function createDynamicSchema(configs: FieldConfig[]) {
  const schemaShape = configs?.reduce(
    (acc, field) => {
      let zodSchema: z.ZodTypeAny;

      switch (field.type) {
        case 'text':
          zodSchema = z.string().default('');
          if (field.required)
            zodSchema = (zodSchema as ZodString).min(
              1,
              `${field.name} is required`
            );
          if (field.pattern)
            zodSchema = (zodSchema as ZodString).regex(
              new RegExp(field.pattern),
              `${field.name} does not match pattern`
            );
          break;

        case 'number':
          zodSchema = z.coerce.number();
          if (typeof field.min === 'number')
            zodSchema = (zodSchema as ZodNumber).min(
              field.min,
              `${field.name} must be at least ${field.min}`
            );
          if (typeof field.max === 'number')
            zodSchema = (zodSchema as ZodNumber).max(
              field.max,
              `${field.name} must be at most ${field.max}`
            );
          break;

        case 'boolean':
          zodSchema = z.boolean();
          break;

        case 'date':
          zodSchema = z.coerce.date();
          break;

        case 'url':
          zodSchema = z.string().url(`${field.name} must be a valid URL`);
          if (field.required)
            zodSchema = (zodSchema as ZodString).min(
              1,
              `${field.name} is required`
            );
          break;

        case 'email':
          zodSchema = z.string().email(`${field.name} must be a valid email`);
          if (field.required)
            zodSchema = (zodSchema as ZodString).min(
              1,
              `${field.name} is required`
            );
          break;

        case 'select':
          if (field.options && field.options.length > 0) {
            zodSchema = field.required
              ? z.enum(field.options as [string, ...string[]])
              : z.union([
                  z.enum(field.options as [string, ...string[]]),
                  z.string()
                ]);
          } else {
            zodSchema = z.string();
          }
          break;

        default:
          zodSchema = z.any();
          break;
      }

      if (!field.required) zodSchema = zodSchema.optional();
      if (field.defaultValue !== undefined)
        zodSchema = zodSchema.default(field.defaultValue);

      acc[field.name] = zodSchema;
      return acc;
    },
    {} as { [key: string]: z.ZodTypeAny }
  );

  return z.object(schemaShape) as ZodObject<any>;
}

export function formatMoney(value) {
  return parseFloat(value)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
