import { QueryClientProvider } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { lazy, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { Toaster } from 'sonner';
import { Router } from 'wouter';

import { queryClient } from '@/lib/api';

import { ConfirmationDialog } from './components/ConfirmationDialogProvider/ConfirmationDialog';
import { ConfirmationDialogProvider } from './components/ConfirmationDialogProvider/ConfirmationDialogProvider';
import { ThemeProvider } from './components/Theme';
import Loader from './components/ui/Loader';
import { sessionStore } from './lib/Session';

const PublicApp = lazy(() => import('@/Features/PublicApp'));
const ProtectedApp = lazy(() => import('./Features/ProtectedApp.tsx'));

const App = observer(function App() {
  const session = sessionStore.decoded;

  return (
    <Suspense fallback={<Loader />}>
      {session ? <ProtectedApp /> : <PublicApp />}
    </Suspense>
  );
});

export default function Root() {
  return (
    <IntlProvider locale={'en'}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <ConfirmationDialogProvider>
            <Router>
              <App />
            </Router>
            <ConfirmationDialog />
            <Toaster closeButton richColors />
          </ConfirmationDialogProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}
