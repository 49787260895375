import { useCallback, useEffect, useState } from 'react';

import { themeContext } from '@/hooks/useTheme';

const isDarkMode = localStorage.getItem('theme')
  ? localStorage.getItem('theme') === 'dark'
  : window?.matchMedia?.('(prefers-color-scheme: dark)').matches ||
    window?.['appearance'] === 'dark';
export function ThemeProvider({ children }) {
  const [theme, toggle] = useState<'dark' | 'light'>(
    isDarkMode ? 'dark' : 'light'
  );

  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = useCallback(
    () =>
      toggle((prev) => {
        return prev === 'dark' ? 'light' : 'dark';
      }),
    []
  );

  return (
    <themeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </themeContext.Provider>
  );
}
