import Cookies from 'js-cookie';
import { useState } from 'react';

import SelectWithSearch from '@/components/SelectWithSearch.tsx';
import { useGetEntities } from '@/Features/Entities/hooks.ts';
import { queryClient } from '@/lib/api.ts';

export const ENTITY_HEADER_NAME = 'entity-name';

const EntitySelect = () => {
  const { data = [], isLoading } = useGetEntities();
  const [currentEntity, setCurrentEntity] = useState(
    Cookies.get(ENTITY_HEADER_NAME) ?? ''
  );
  return (
    <div className={'min-w-[100px]'}>
      <SelectWithSearch
        emptyMessage={'No entities available'}
        value={currentEntity}
        onChange={(v) => {
          setCurrentEntity(v);
          Cookies.set(ENTITY_HEADER_NAME, v, {
            secure: true,
            sameSite: 'None'
          });
          queryClient.refetchQueries({ type: 'active' });
        }}
        searchPlaceholder={'search entity'}
        disabled={isLoading || !data?.length}
        descriptionContentClassName={'text-xs p-2 text-gray-500'}
        options={data?.map((v) => ({
          label: v.name,
          value: v.name,
          description: v.description as string
        }))}
      />
    </div>
  );
};

export default EntitySelect;
