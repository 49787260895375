import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import { PropsWithChildren, ReactNode, useMemo, useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem
} from '@/components/ui/command';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';

type Option = {
  label: string | ReactNode;
  value: any;
  searchText?: string;
  description?: string;
  disabled?: boolean;
  render?: (option?: Option) => ReactNode;
  category?: string;
};

type OptionGroup = [string, Option[]];

type SelectWithSearchProps = {
  disabled?: boolean;
  value: string | number;
  options: Option[] | Record<string, Option[]>;
  renderValue?: (option: Option) => any;
  emptyMessage?: string;
  searchPlaceholder?: string;
  onChange: (value: any) => void;
  descriptionSide?: 'right' | 'left';
  descriptionAlign?: 'start' | 'end';
  descriptionContentClassName?: string;
  disableUnselect?: boolean;
  hideOnNoOptions?: boolean;
  onSearch?: (search: string) => void;
  isLoading?: boolean;
  searchValue?: string;
};

const SelectWithSearch = (props: PropsWithChildren<SelectWithSearchProps>) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    onSearch,
    disabled,
    value,
    options = [],
    searchPlaceholder = 'Search...',
    emptyMessage = 'No options available',
    descriptionAlign = 'start',
    descriptionContentClassName,
    descriptionSide = 'right',
    disableUnselect,
    children,
    renderValue,
    onChange,
    hideOnNoOptions,
    isLoading = false,
    searchValue
  } = props;

  const optionsList = useMemo((): OptionGroup[] => {
    if (Array.isArray(options)) {
      const hasCategory = options.some((option) => 'category' in option);

      if (hasCategory) {
        const categorizedOptions: Record<string, Option[]> = {};
        for (const option of options) {
          const category = option.category || 'Uncategorized';
          if (!categorizedOptions[category]) {
            categorizedOptions[category] = [];
          }
          categorizedOptions[category].push(option);
        }
        return Object.entries(categorizedOptions);
      }
      return [['', options]];
    }
    return Object.entries(options);
  }, [options]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    for (const [group, groupOptions] of optionsList) {
      const option = groupOptions.find((option) => option.value === value);
      if (option) return { group, option };
    }
    return null;
  }, [value, optionsList]);

  if (hideOnNoOptions && !optionsList?.length) return null;

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        {children || (
          <Button
            variant="outline"
            role="combobox"
            disabled={
              disabled ||
              optionsList.every(
                ([_, groupOptions]) => groupOptions.length === 0
              )
            }
            aria-expanded={isOpen}
            className="justify-between w-full"
          >
            {(!!selectedOption?.option?.label &&
              renderValue?.(selectedOption?.option)) ||
              selectedOption?.option?.label ||
              (!optionsList?.length && emptyMessage) ||
              'Select option...'}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput
            placeholder={searchPlaceholder}
            className="h-9"
            value={searchValue}
            onValueChange={onSearch}
          />
          <ScrollArea className="max-h-[200px] overflow-auto">
            {isLoading ? (
              <div className="p-2">
                <Skeleton className="h-4 w-full mb-2" />
                <Skeleton className="h-4 w-full mb-2" />
                <Skeleton className="h-4 w-full" />
              </div>
            ) : (
              <>
                <CommandEmpty>{emptyMessage}</CommandEmpty>
                {optionsList.map(([group, options], index) => (
                  <CommandGroup key={`${group}-${index}`} heading={group}>
                    {options.map((option) => (
                      <HoverCard key={`${option.value}-${option.label}`}>
                        <HoverCardTrigger>
                          <CommandItem
                            value={`${option.value} ${option.label ?? ''} ${option.searchText ?? ''}`.trim()}
                            onSelect={() => {
                              if (!option.disabled) {
                                onChange(
                                  option.value === value && !disableUnselect
                                    ? ''
                                    : option.value
                                );
                                setIsOpen(false);
                              }
                            }}
                            className={cn(
                              option.disabled &&
                                'opacity-50 cursor-not-allowed',
                              value === option.value && 'bg-gray-100'
                            )}
                          >
                            {option.render?.(option) ??
                              option.label ??
                              option.value}
                            <CheckIcon
                              className={cn(
                                'ml-auto h-4 w-4',
                                value === option.value
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )}
                            />
                          </CommandItem>
                        </HoverCardTrigger>
                        {option.description && (
                          <HoverCardContent
                            side={descriptionSide}
                            align={descriptionAlign}
                            className={descriptionContentClassName}
                          >
                            {option.description}
                          </HoverCardContent>
                        )}
                      </HoverCard>
                    ))}
                  </CommandGroup>
                ))}
              </>
            )}
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default SelectWithSearch;
