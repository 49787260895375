import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

import('ag-grid-enterprise').then(({ LicenseManager }) => {
  Object.defineProperty(LicenseManager.prototype, 'validateLicense', {
    value: () => false
  });
  Object.defineProperty(LicenseManager.prototype, 'isDisplayWatermark', {
    value: () => false
  });
});
