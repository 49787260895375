import { useMutation, useQuery } from '@tanstack/react-query';

import { crmApi } from '@/lib/crmApi';

export const useGetEntities = () => {
  return useQuery({
    queryFn: () => crmApi.entities.getAll().then((res) => res.data),
    queryKey: ['/entities']
  });
};

type EntityArgs = {
  name: string;
  description: string;
};

export const useCreateEntity = () =>
  useMutation({
    mutationKey: ['/payment-gateways'],
    mutationFn: (entityArgs: EntityArgs) =>
      crmApi.entities
        .entitiesCreate(entityArgs)
        .then((res) => res)
        .catch(() => null)
  });

export const useUpdateEntity = () =>
  useMutation({
    mutationKey: ['/entities'],
    mutationFn: ({ name, description }: EntityArgs) =>
      crmApi.entities
        .updateByName(name, { description })
        .then((res) => res)
        .catch(() => null)
  });

export const useDeleteEntity = () =>
  useMutation({
    mutationKey: ['/entities'],
    mutationFn: (name: string) =>
      crmApi.entities
        .deleteByName(name)
        .then((res) => res)
        .catch(() => null)
  });
